<template>
    <div>
        <!-- <div v-if="!$route.path.includes('sessions') && !$route.path.includes('set-sec-password') && !$route.path.includes('register')" class="footer_wrap mt-auto" ref="footer"> -->
        <div v-if="!$route.path.includes('login')" class="footer_wrap mt-auto d-md-block d-none" ref="footer">
            <!-- Footer Start -->
            <div class="app-footer">
                <b-container fluid class="py-3 px-5 border-top">
                    <b-row>
                        <b-col cols="12" class="mb-4">
                            <div class="text-black text-16 font-weight-bold">Powered by</div>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center align-items-start">
                        <b-col v-for="company in companies" :key="company.name" cols="12" md="3"
                            class="d-flex align-items-center mb-3">
                            <a :href="company.link" class="d-flex">
                                <b-img :src="company.logo" :alt="`${company.name} Logo`" fluid class="mr-3 mt-2"
                                    style="height: 28px;" />
                                <div class="text-left">
                                    <p class="text-10 mb-2" style="color: #7C7C7C;">{{ company.id }}</p>
                                    <p class="text-10 text-black font-weight-bold">{{ company.address }}</p>
                                </div>
                            </a>
                        </b-col>
                    </b-row>
                </b-container>
                <!-- <b-row align-h="between" class="">
                <b-col cols="" class="position-relative col-auto">
                    <router-link tag="a" to="/web/homepage" class="nav-item-hold">
                        <div :class="{ 'nav-icon': true, 'active': $parent.path == 'homepage', 'inactive': $parent.path != 'homepage' }">
                            <img :src="$parent.path == 'homepage' ? require('./assets/images/flx_footer/home_active.png') : require('./assets/images/flx_footer/home_icon.png')"
                                class="nav-icon" />
                        </div>
                    </router-link>
                </b-col>
                <b-col cols="" class="col-auto">
                    <router-link tag="a" to="/web/wallet" class="nav-item-hold ">
                        <div :class="{ 'nav-icon': true, 'active': $parent.path == 'wallet', 'inactive': $parent.path != 'wallet' }">
                            <img :src="$parent.path == 'wallet' ? require('./assets/images/flx_footer/wallet_active.png') : require('./assets/images/flx_footer/wallet_icon.png')"
                                class="nav-icon" />
                        </div>
                    </router-link>
                </b-col>

                <b-col cols="" class="col-auto">
                    <router-link tag="a" to="/web/trade" class="nav-item-hold">
                        <div :class="{ 'nav-icon': true, 'active': $parent.path == 'trade', 'inactive': $parent.path != 'trade' }">
                            <img :src="$parent.path == 'trade' ? require('./assets/images/flx_footer/trade_active.png') : require('./assets/images/flx_footer/trade_icon.png')"
                                class="nav-icon" />
                        </div>
                    </router-link>
                </b-col>

                <b-col cols="" class="col-auto">
                    <router-link tag="a" to="/web/ib_tree" class="nav-item-hold">
                        <div :class="{ 'nav-icon': true, 'active': $parent.path == 'ib_tree', 'inactive': $parent.path != 'ib_tree' }">
                            <img :src="$parent.path == 'ib_tree' ? require('./assets/images/flx_footer/tree_active.png') : require('./assets/images/flx_footer/tree_icon.png')"
                                class="nav-icon" />
                        </div>
                    </router-link>
                </b-col>

                <b-col cols="" class="col-auto">
                    <router-link tag="a" to="/web/commission" class="nav-item-hold">
                        <div :class="{ 'nav-icon': true, 'active': $parent.path == 'commission', 'inactive': $parent.path != 'commission' }">
                            <img :src="$parent.path == 'commission' ? require('./assets/images/flx_footer/commission_active.png') : require('./assets/images/flx_footer/commission_icon.png')"
                                class="nav-icon" />
                        </div>
                    </router-link>
                </b-col>
            </b-row> -->
                <!-- fotter end -->
            </div>
        </div>
        <div v-if="!$route.path.includes('login')" class="footer footer_wrap">
            <div class="footer_mobile">
                <span class="footer_title">Powered by</span>
                <div class="footer_logo">
                    <a v-for="company in companyMobile" :key="company.name" :href=company.link>
                        <img :src="company.logo" alt="">
                    </a>

                </div>
            </div>

        </div>
    </div>


</template>
<script>
export default {
    data() {
        return {
            companies: [
                {   
                    name: "IDC",
                    logo: require("./assets/images/itc_footer/idc-logo.png"),
                    id: "IDC CAPITAL SDN. BHD. 202401009559 (1555409-P)",
                    address: "Level 12-01, Menara G-Vestor, Pavilion Embassy, Kompleks Ampang, No.200 Jalan Ampang, 50450 Kuala Lumpur.",
                },
                {
                    name: "MPV1",
                    logo: require("./assets/images/itc_footer/mpv-logo.png"),
                    id: "Labuan Office",
                    address: "Unit 4A(1), Main Office Tower, Financial Park Complex Labuan, Jalan Merdeka, 87000 Labuan F.T. Malaysia.",
                    link: "https://mpvworld.org/",
                },
                {
                    name: "MPV",
                    logo: require("./assets/images/itc_footer/mpv-logo.png"),
                    id: "KL Office",
                    address: "Level 12-08, Menara G-Vestor, Pavilion Embassy, Kompleks Ampang, No.200 Jalan Ampang, 50450 Kuala Lumpur.",
                    link: "https://mpvworld.org/"

                },
                {
                    name: "Unity Trust Berhad 202101015253 (1415553-A)",
                    logo: require("./assets/images/itc_footer/unity-logo.png"),
                    id: "Unity Trust Berhad 202101015253 (1415553-A)",
                    address: "Unit 3A10, Block F Pusat Perdagangan Philieo 9, Jalan 16/11, Petaling Jaya, 46350 Selangor, Malaysia.",
                    link:"https://www.unitytrust.my/"
                }
            ],
            companyMobile: [
                {
                    name: "IDC",
                    logo: require("./assets/images/itc_footer/idc-logo.png"),
                },
                {
                    name: "MPV",
                    logo: require("./assets/images/itc_footer/mpv-logo.png"),
                    link: "https://mpvworld.org/",
                },

                {
                    name: "Unity Trust Berhad 202101015253 (1415553-A)",
                    logo: require("./assets/images/itc_footer/unity-logo.png"),
                    link: "https://www.unitytrust.my/"
                },

            ]
        };
    }
}
</script>
