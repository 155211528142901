import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./assets/styles/sass/global.scss";
import i18n from "./system/lang/lang.js";
import { createPinia } from "pinia";
import VueQRCodeComponent from 'vue-qrcode-component';
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import {
  getMemberInfo, getUserNewsList, getPackage, country_list, getUserPackage, getDownloadLink
} from "@/system/request/api/api";

Vue.use(VueGoodTablePlugin);
Vue.component('qr-code', VueQRCodeComponent);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(createPinia())

Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue();

new Vue({
  data() {
    return {
      isLoading: false,
      needRiskLevel: false,
      cannotUpgrade: true,
      userInfo: {
        invest: 0,
        user_group_id: 0,
        rank: {
          rank_name_en: ''
        },
        package: {
          price: 0,
        }
      },
      selectedWallet: '',
      currentAddress: '',
      username: '',
      memberList: [],
      countryOptions: [],
      teamList: [],
      newsList: [],
      packageList: [],
      userPackages: [],
      newsItem: {
        description: ''
      },
      promotionItem: {
        description: ''
      },
      eventItem: {
        description: ''
      },
      assetInfo: {
        static_bonus: 0,
        special_bonus: 0,
        dynamic_bonus: 0,
        level_bonus: 0,
      },
      modal: {
        title: "",
        msg: "",
        action: false,
        error: false,
      },
      globalSetting: null,
      userId: ''
    }
  },
  watch: {
    '$i18n.locale'(){this.getNewsList(1);this.getBannerSList()},
    'userInfo': 'checkCurrentDate',
  },
  methods: {
    changeLan(lan) {
      if (lan == 'zh' || lan == 'en' || lan == 'in' || lan == 'zhHant' || lan == 'ja' || lan == 'ko') {
        localStorage.setItem('lan', lan);
        this.$i18n.locale = lan;

      } else {
        this.$root.makeToast("warning", this.$t('coming_soon'));
      }
    },
    getStakingType() {
      var self = this;
      self.isLoading = true;
      var result = getPackage();
      result
        .then(function (value) {
          self.packageList = value.data.data.user_group;
          self.isLoading = false;


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    checkCurrentDate() {
      this.cannotUpgrade = true;
      var todayDate = new Date().getDate();
      if (todayDate <= 3) {
        if (this.userInfo.user_group_id == 0 || this.userInfo.user_group_id >= 3) {
          this.cannotUpgrade = false;

        }
        if (this.userInfo.user_group_id >= 3 && this.userInfo.risk_level == 0)
          this.needRiskLevel = true;

      } else {
        if (this.userInfo.user_group_id == 0) {
          this.cannotUpgrade = false;

        }
      }

      console.log(this.cannotUpgrade);
    },
    getCountryList() {
      var self = this;
      self.countryOptions = [];
      this.isLoading = true;
      var result = country_list();
      result
        .then(function (value) {
          self.$root.isLoading = false;
          var rootDataList = value.data.data;
          for (let i = 0; i < rootDataList.length; i++) {
            rootDataList[i]["value"] = rootDataList[i].id;
            rootDataList[i]["name"] = self.$i18n.locale == 'en' ? rootDataList[i].name_en : rootDataList[i].name;

            self.countryOptions.push(rootDataList[i]);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getGlobalSetting() {
      var result = getDownloadLink();
      var self = this;
      this.isLoading = true;
      result
        .then(function (value) {
          self.isLoading = false;
          self.globalSetting = value.data.data.system;
          console.log(self.globalSetting.YESTERDAY_USD);
        })
        .catch(function () {
          self.isLoading = false;
        });
    },
    getPackage(id) {
      var self = this;
      self.isLoading = true;
      var result = getUserPackage(id);
      result
        .then(function (value) {
          self.isLoading = false;
          self.userPackages = value.data.data.user_package.data;
          // console.log(self.userPackages);

        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getInfo() {
      var self = this;
      self.isLoading = true;
      var result = getMemberInfo();
      result
        .then(function (value) {
          self.isLoading = false;
          self.userInfo = value.data.data;


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async getNewsList(type) {
      var news = [];
      var result = getUserNewsList(this.$i18n.locale == 'zh' ? 'cn' : this.$i18n.locale, 1, type);
      var self = this;
      await result
        .then(function (value) {
          self.newsList = value.data.data.record.data;
          news = self.newsList;

        })
        .catch(function (error) {
          console.log(error);
        });
        return Promise.resolve(news);
    },
    makeToast(variant = null, msg, type) {
      this.msg = msg;
      this.variant = variant;
      this.type = type;
      this.modal.title = msg;
      this.$bvModal.show('modal-errorMsg');
      // this.$bvToast.toast(msg, {
      //   // // title: ` ${variant || "default"}`,
      //   variant: variant,
      //   solid: true,
      // });
    },
    checkKYC() {
      if (this.userInfo.check_kyc.can_withdraw == 'approved') {
        this.$bvModal.show('withdraw');

      } else {
        this.modal = {
          title: this.$t('kyc'),
          msg: this.$t('kyc_hint'),
          action: false,
          error: true,
        };
        this.$bvModal.show('modal-errorMsg');

      }
    },
    clearErrorMsg() {
      this.$root.modal = {
        title: "",
        msg: "",
        action: false,
        error: false,
      };
      console.log(this.modal);

    }
  },
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
