import { getToken, removeToken } from "./tokenHandling";


export default async (to, from, next) => {
  var token = getToken();
  var userInfo = localStorage.getItem("info") || '';


  try {
    userInfo = JSON.parse(userInfo);
  } catch (e) {
    userInfo = '';
  }

  if (to.meta.requiresAuth) {
    if (token.length > 0) {
      if (userInfo.password2 == null) {
        next('/web/set-sec-password');
      } else if (userInfo.check_kyc.can_withdraw != 'approved') {
        next('/web/kyc');
      } else {
        next();

      }
    } else {
      removeToken();
      next('/web/sessions/login');
    }

  } else {
    next();

  }
};
